<template>
  <div>
    <CRow class="justify-content-center">

      <CCol md="8" lg="6">

        <CCard>
          <CCardHeader>
            <CIcon name="cil-swap-horizontal" />
            Swap Token
            <div class="card-header-actions">
              <CIcon class="mr-3 text-info" name="cil-equalizer" />
              <CIcon class="text-info" name="cil-history"/>
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow class="mb-2">
              <CCol col="2">FROM</CCol>
              <CCol class="text-right">{{usdt}} USDT</CCol>
            </CRow>
            <CRow >
              <CCol col="12">
                <CInput
                    color="link"
                    type="number"
                    placeholder="0.0"
                    v-model="total"
                >
                  <template #append>
                    <CButton color="secondary" class="pl-2" style="width: 7rem">
                      <img class="mr-2" height="20" src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/72/Tether-USDT-icon.png"/>
                      <span class="text-white">USDT</span>
                    </CButton>
                  </template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="text-center">
                <CIcon name="cil-loop-circular"/>
              </CCol>
            </CRow>

            <CRow class="mb-2">
              <CCol col="2">TO</CCol>
              <CCol class="text-right">{{next}} NEXT</CCol>
            </CRow>
            <CRow >
              <CCol col="12">
                <CInput
                    :disabled="true"
                    type="number"
                    placeholder="0.0"
                    v-model="total"
                >
                  <template #append>
                    <CButton color="secondary" class="pl-2" style="width: 7rem">
                      <img class="mr-2" height="22" src="img/token/nextart.png"/>
                      <span class="text-white">NEXT</span>
                    </CButton>
                  </template>
                </CInput>

              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <div class="float-left w-25">Price</div>
                <div class="float-left text-right w-75"> <h6>1 USDT Per NART <CIcon class="text-white ml-2" name="cil-loop-circular"/></h6></div>
              </CCol>
<!--              <CCol class="text-right"><h6> 0.1 USDT Per NART <CIcon class="text-white ml-5" name="cil-loop-circular"/></h6> </CCol>-->
            </CRow>
            <CRow class="mt-3">
              <CCol>
                <CButton  class="w-100" :color="(!this.disApprove == true)?'secondary':'success'"  @click="onApptove" :disabled="!disApprove"> Approve </CButton>
              </CCol>

              <CCol>
                <CButton  class="w-100" :color="(!this.disSwap == true)?'secondary':'success'"  @click="onSwap"  :disabled="!disSwap" > Swap </CButton>
              </CCol>
            </CRow>

          </CCardBody>
        </CCard>

      </CCol>
      <CCol md="7" v-if="false">

        <CCard>
          <!--          <CCardHeader>-->
          <!--            <h4>Farms & Staking </h4>-->
          <!--          </CCardHeader>-->
          <CCardBody>

            <CRow>
              <CCol col="12">
                <h4>NEXTART Stats</h4>
              </CCol>
              <CCol>


                <div class="progress-group">
                  <div class="progress-group-header">
                    <CIcon name="cil-institution" class="progress-group-icon"/>
                    <span class="title">Total NEXTART Supply</span>
                    <span class="ml-auto font-weight-bold">100000200000000</span>
                  </div>
                </div>
                <div class="progress-group">
                  <div class="progress-group-header">
                    <CIcon name="cil-fire" class="progress-group-icon"/>
                    <span class="title">Total NEXTART Burned</span>
                    <span class="ml-auto font-weight-bold">10000020</span>
                  </div>
                </div>
                <div class="progress-group">
                  <div class="progress-group-header">
                    <CIcon name="cil-people" class="progress-group-icon"/>
                    <span class="title">NEXTART Holder</span>
                    <span class="ml-auto font-weight-bold">100020</span>
                  </div>
                </div>
              </CCol>
            </CRow>



          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
<!--    <MetamaskConnect user-message="msg" @onComplete="onComplete"/>-->


  </div>
</template>

<script>

import {ABI, address, addressUSDT, addressBid} from "@/store/modules/Metamask/constants/escrowContract";
import Web3 from "web3";
  import {mapGetters} from 'vuex'
  import Vue from 'vue'
  import VueQuillEditor from 'vue-quill-editor'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
import DialogConsistency from "@/project/views/pages/dialog/DialogConsistency";
  Vue.use(VueQuillEditor)
export default {
  name: 'Profile',
  components: {DialogConsistency},
  data: function () {
    return {
      url:"https://d1csarkz8obe9u.cloudfront.net/posterpreviews/mr-bread-logo-design-template-8a8bf7264deccf537f8dd318785ebd6f_screen.jpg?ts=1579209248",
      cataigory:[{
        text:"asss",
        value:"USDT"
      }],
      decrption:{
        contactAddress:"xxxx",
        tokenId:"",
        blockchain:"",
        metadata:"",
        createDate:""
      },
      spender:address, // NEXTART
      contactAddress: addressUSDT, // USDT
      total:0,

      disApprove:false,
      disSwap:false
    }
  },
  mounted () {

  },
  // 8ac7230489e80000
  created () {

  },


  beforeDestroy () {

  },

  methods: {

    // onApptove(){
      // var obj = {};
      // obj.spender = this.spender;
      // obj.contactAddress = this.contactAddress;
      // obj.value = this.total;
      // this.$store.dispatch('metamask/onContractApprove',obj)
    // },
    onApptove(){
      var obj = {};
      obj.value = this.total;
      this.$store.dispatch('metamask/onBid',obj)
    },

    onSwap(){
      var obj = {};
      obj.contactAddress = this.contactAddress;
      obj.value = this.total
      this.$store.dispatch('metamask/onSwapToken',obj)
    },

  },

  computed:{
    ...mapGetters({
      isSwap: "metamask/objisApptove",
      next:'metamask/objTokenNext',
      usdt:'metamask/objTokenUSDT',
      complete: "metamask/objComplete"
    })
  },

  watch: {
    total(value){
      if(value > 0){
        this.disApprove = true
      }
    },

    isSwap(value){
      this.disSwap = value;
    },

    complete(){
      this.total = 0;
      this.disApprove = false;
      this.$store.commit("metamask/setisApptove", false);
    }

  }
}
</script>
